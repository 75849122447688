#myNavbar{
    background-color: #fffefe !important;
}

.dropdown-item{
    color: aliceblue !important;
}
.nav-link{
    color: rgb(0, 0, 0) !important;
    font-size: 18px;
}